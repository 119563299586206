.practice-step-sec {
	display: flex;
	flex-direction: column;
	gap: 40px;
	min-height: 100vh;
	.step-navigation {
		padding: 40px 0;
		border-bottom: 1px solid #E9E9E9;
	}
	.custom-progress {
		position: fixed;
		top: 0;
		width: 100%;
		background-color: #F6F6F6;
		.progress-bar {
			position: relative;	
			background-color: var(--bs-primary);
			height: 4px;
			.progper {
				position: absolute;
				right: 0;
				top: 10px;
			}
		}

		.btn-close {
			position: absolute;
			right: 20px;
			top: 15px;
		}
	}
	.step-content-area {
		flex-grow: 1;
	}
	.btn-link {
		color: var(--bs-primary);
	}

	// .btn-primary
	// {
	// 	background-color: var(--bs-primary);
	// 	border-color: var(--bs-primary);
	// }
	.btn-secondary {
		background-color: #9d9d9d !important;
		border: none !important;
	}

	.fw-bold {
		font-weight: 600 !important;
	}

	.text-muted {
		color: #9d9d9d !important;
	}

	small {
		font-size: 14px;
		line-height: 18px;
	}

	.fs-1 {
		font-size: 44px !important;
	}

	.default-card {
		border-radius: 8px !important;
	}

	.btn-img {
		position: relative;
		top: -2px;
		margin-left: 5px;
	}

	.top-menu-sec {
		ul {
			list-style: none;
			gap: 40px;
			.step-list { 
				display: flex;
				gap: 15px;
				&:last-child {
					margin-right: 0;
				}

				.img-box {
					height: 50px;
					width: 50px;
					background-color: #fff;
					border: 1px solid #d8d8d8;
					border-radius: 50%;
					text-align: center;
					line-height: 48px; 
					color: #9d9d9d;
					.step-done {
						display: none;
					}
				}

				.txt-box {
					display: none;

					small {
						color: var(--bs-primary);
					}
				}

				&.active { 
					.txt-box {
						display: block;
					}

					.img-box {
						background-color: var(--bs-primary);
						border-color: var(--bs-primary);
						color: #fff;

						img {
							-webkit-filter: brightness(0) invert(1);
							filter: brightness(0) invert(1);
						}
					}
				}

				&.completed {
					.img-box {
						background-color: #F2F7FE;
						border-color: 1px solid  var(--bs-primary);
						color: var(--bs-primary);

						.step-done {
							display: initial;
						}

						.step-notdone {
							display: none;
						}
					}
				}
			}
		}
	}
	.step-content-area-flex {
		display: flex;
		gap: 40px;
		flex-direction: column;
	}

	.steps-title-section {
		border-bottom: 1px solid #E9E9E9; 
		padding-bottom: 30px;
		display: flex;
		flex-direction: column;
		gap: 5px;

		.steps-title {
			font-size: 32px;
			line-height: 40px;
		}
		.steps-subtitle {
			font-size: 22px;
			line-height: 30px;
			font-weight: 600;
		}
		.steps-desc {
			font-size: 16px;
			line-height: 24px; 
		}
	}

	.select-practice-btn {
		margin: 0 0 30px 0;

		input {
			&:hover {
				box-shadow: none !important;
				outline: none !important;
			}
		}

		label.btn {
			display: flex;
			flex-direction: column;
			gap: 15px;
			border: 1px solid #E9E9E9;
			border-radius: 6px;
			padding: 10px;
			align-items: center;
			justify-content: center;
			min-height: 150px;
			background-color: var(--bs-white);
		}

		.btn-check {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			pointer-events: none;
		}

		input[type="radio"]:not(checked)+label {
			border: 1px solid #E9E9E9 !important;
     		background-color: var(--bs-white);
		}

		input[type="radio"]:checked+label {
			border: 1px solid var(--bs-primary) !important;
      		background-color: rgba(22,118,238,.05);
		}
		
		.practice-type-title {
			font-size: 18px;
      		font-weight: 500;
		}
	}

	.profile-logo-sec {
		.img-box {
			width: 120px;
			height: 120px;
			background-color: #d8d8d8 !important;
			border: 2px solid #d8d8d8 !important;

			.rounded-circle {
				max-width: 116px;
				max-height: 116px;
				object-fit: cover;
			}

			.btn {
				background-color: #ed143d;
				border: 2px solid #fff;
				height: 32px;
				width: 32px;
				line-height: 0;
			}
		}
	}

	.warning-box {
		background-color: #fcf7e4;
		border-color: #faf0ce !important;
		color: #876d40;

		small {
			font-size: 12px;
		}
	}

	.map-sec,
	.search-sec {
		border-radius: 8px;
		overflow: hidden;
		-webkit-box-shadow: 3px 0px 6px 0px rgba(0, 0, 0, 0.16);
		-moz-box-shadow: 3px 0px 6px 0px rgba(0, 0, 0, 0.16);
		box-shadow: 3px 0px 6px 0px rgba(0, 0, 0, 0.16);
	}

	.days-checklist {
		margin: 0;
		padding: 0;
		list-style: none;

		.form-check {
			position: relative;
			z-index: 0;
			padding: 0;
			margin: 5px 10px;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			input {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 15px;
				right: auto;
				margin: auto 0;

				&:focus {
					box-shadow: none !important;
				}
			}

			label {
				padding: 7px 15px 7px 40px;
			}

			input[type="checkbox"]:checked+label {
				border-color: var(--bs-primary);
				background-color: rgba(22, 118, 238, 0.05);
			}
		}
	}

	.open-hours-info {
		.hours-box {
			.day-title {
				width: 400px;
			}
		}
	}

	textarea {
		resize: none;
		color: #333 !important;
		padding: 7px 15px !important;

		&:focus {
			border-color: #ced4da;
		}
	}

	.editor-box {
		overflow: hidden;
		padding: 0;

		.editor-strip {
			border-bottom: 1px solid #d8d8d8;
		}

		textarea {
			width: 100%;
			border: none;
			padding: 15px;

			&:focus,
			&:focus-within,
			&:focus-visible {
				border: none;
				outline: none;
			}
		}
	}

	.editor-box.form-control {
		border: none;
	}

	.keywords-box {
		height: 105px;
		padding: 7px 15px;

		span.badge {
			display: inline-block;
			background-color: #d8d8d8;
			color: #333;
			font-size: 14px;
			font-weight: 400 !important;
			margin-right: 15px;

			&:last-child {
				margin-right: 0;
			}

			.btn {
				line-height: 0;
			}
		}
	}

	.custom-check-list { 
		.form-check {
			position: relative;
			z-index: 0;
			padding: 0;
			margin: 0 15px 15px 0 !important;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			input {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 15px;
				right: auto;
				margin: auto 0;

				&:focus {
					box-shadow: none !important;
				}
			}

			label {
				padding: 10px 15px 10px 40px;
			}

			input[type="checkbox"]:checked+label {
				border-color: var(--bs-primary);
				background-color: rgba(22, 118, 238, 0.05);
				color: var(--bs-primary);
			}
		}
	}

	.insurance-type {
		.form-check {
			label {
				color: #9d9d9d;
			}

			input[type="radio"]:checked+label {
				color: #333;
			}
		}
	}

	.insurance-list {
		.badge {
			border-color: var(--bs-primary);
			background-color: rgba(22, 118, 238, 0.05);
			color: #333 !important;
			padding: 10px 15px 10px 15px !important;
			font-weight: 400;
			font-size: 16px;
			line-height: 16px;
			margin: 15px 15px 15px 0;

			&:last-child {
				margin-right: 0;
			}

			.btn {
				line-height: 0;
				border: none !important;
				background: none !important;
			}
		}
	}

	.ins-select-type {
		width: 100%;
		max-width: 340px;
	}

	.img-gallery-practice {
		gap: 20px;
		.img-box {
			height: 115px;
			width: 115px; 
			position: relative;

			img {
				border-radius: 5px;
				max-width: 100%;
			}

			&:last-child {
				margin-right: 0;
			}

			.delete-img-btn {
				background-color: #d9534f;
				width: 24px;
				height: 24px;
				border-radius: 50px;
				line-height: 0;
				right: -8px;
				color: #fff;
				top: -8px;
				font-size: 12px;
			}
		}
	}

	.upload-sec {
		-webkit-box-shadow: 3px 0px 6px 0px rgba(0, 0, 0, 0.16);
		-moz-box-shadow: 3px 0px 6px 0px rgba(0, 0, 0, 0.16);
		box-shadow: 3px 0px 6px 0px rgba(0, 0, 0, 0.16);

		.upload-input-box {
			border-radius: 10px !important;
			border: 1px dashed var(--bs-primary);
			border-color: var(--bs-primary);
			background-color: rgba(22, 118, 238, 0.05);
		}
	}

	.upload-progress {
		border-radius: 10px !important;

		.progress-status {
			span {
				color: var(--bs-primary);
			}
		}

		.progress {
			height: 6px;

			.progress-bar {
				background-color: #1c7cd5;
			}
		}
	}

	.bottom-btn-sec {
		.btn {
			font-weight: normal !important;
			padding: 10px 30px;
			border-radius: 3px;
		}
	}

	.edu-box {
		background-color: #F2F7FE;
		border: 1px solid var(--bs-primary);
		border-radius: 10px;
		align-items: center;
		.association-name, 
		.received-date, 
		.registration-no {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}
		
	}

	.add-new-edu {
		.btn {
			// width: 38px;
			margin-top: 32px;
		}
	}

	.add-pms {
		.input-box {
			width: 100%;
			max-width: 330px;
		}

		.btn-primary {
			height: 38px;
			margin-top: 7px;
		}
	}

	.provider-info {
		label {
			font-weight: normal !important;
		}

		span.badge {
			display: inline-block;
			background-color: #d8d8d8;
			color: #333;
			font-size: 14px;
			font-weight: 400 !important;
			margin-right: 15px;

			&:last-child {
				margin-right: 0;
			}

			.btn {
				line-height: 0;
			}
		}
	}

	.clinic-search {
		border-bottom: 1px solid #E9E9E9;
    	padding-bottom: 40px;
		margin-bottom: 40px;
	}

	.clinic-search-results {
		display: flex;
		flex-direction: column;
		gap: 30px;
		padding: 40px 0;
		border-bottom: 1px solid #E9E9E9;
	}

	.open-hours-info {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.avatar {
		display: flex;
		gap: 15px;
		align-items: center;
		&-image {
			flex-shrink: 0;
			width: 60px;
			height: 60px;
			border-radius: 100%;
			overflow: hidden;
			img {
				object-fit: cover;
				max-width: 100%;
			}
		}
		&-content {
			display: flex;
			flex-direction: column;
			gap: 2px;	
		}
		&-title {
			font-size: 16px;
			font-weight: 500;	
		}
		&-sub-title {
			font-size: 14px;
			color: #A1A1AA;	
		}
	}
	
	.open-to-work {
		margin-top: 40px;
		.open-to-work-days {
			margin-top: 30px;
		}
		.steps-title-section {
			border: none;
			padding: 0;
			margin: 0;
		}
	}
}

.card-stripe {
	.StripeElement {
		padding: 10px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.practice-step-sec .top-menu-sec ul .step-list {
		margin: 0 8px;
		// text-align: center;
	}

	.practice-step-sec .top-menu-sec ul .step-list.active .img-box {
		margin: auto;
	}

	.practice-step-sec .top-menu-sec ul .step-list.active .txt-box {}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.practice-step-sec .top-menu-sec ul .step-list {
		margin: 0 7px 0 0;
		// text-align: center;
	}

	.practice-step-sec .top-menu-sec ul .step-list .img-box {
		margin: auto;
	}

	.practice-step-sec .days-checklist .form-check {
		margin: 5px auto;
	}
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
	.btn {
		font-size: 14px;
	}

	.steps-container {
		.row:first-child {
			h2 {
				font-size: 20px;
			}

			.fs-5 {
				font-size: 16px !important;
			}

			p {
				font-size: 14px;
			}
		}
	}

	.practice-step-sec .profile-logo-sec .img-box {
		width: 84px;
		height: 84px;
	}

	.practice-step-sec .profile-logo-sec span {
		font-size: 14px;
	}

	.practice-step-sec .profile-logo-sec .img-box .btn {
		height: 26px;
		width: 26px;
		top: -4px !important;
	}

	.practice-step-sec .profile-logo-sec .img-box .rounded-circle {
		max-width: 80px;
		max-height: 80px;
	}

	.practice-step-sec .top-menu-sec ul .step-list {
		margin: 0;
		text-align: center;
		display: none;
	}

	.practice-step-sec .top-menu-sec ul .step-list.active {
		display: block;
	}

	.practice-step-sec .top-menu-sec ul .step-list .img-box {
		margin: auto;
	}

	.practice-step-sec .days-checklist {
		flex-wrap: wrap;
	}

	.practice-step-sec .days-checklist .form-check {
		margin: 5px 14px 5px 0;
		font-size: 14px;
		min-width: 65px;
	}

	.practice-step-sec .days-checklist .form-check label {
		padding: 7px 7px 7px 25px;
	}

	.practice-step-sec .days-checklist .form-check input {
		left: 7px;
	}

	.practice-step-sec .open-hours-info {
		overflow: hidden;
	}

	.practice-step-sec .searchNewIns {
		width: calc(100% - 92px) !important;
	}

	.practice-step-sec .insurance-type {
		font-size: 14px;
		justify-content: space-between;
	}

	.practice-step-sec .insurance-list .badge {
		width: 100%;
		font-size: 14px;
		display: flex;
		margin: 0 0 15px 0;
		justify-content: center;

		span {
			text-align: left;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.btn {}
	}

	.practice-step-sec .custom-check-list .form-check label {
		font-size: 14px;
		padding: 7px 8px 7px 30px;
	}

	.practice-step-sec .custom-check-list .form-check input {
		left: 8px;
	}
}