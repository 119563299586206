// Custom.scss 

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: #3054CB;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
// $theme-colors: (
//   "primary": $primary,
// );

// 5. Include remainder of required parts
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/helpers";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/forms";


// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";


//
// Import Bootstrap Icons w/ custom path to fonts folder
//

@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.scss";


@import "practiceSteps";

//
// Custom styles
//

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body {
  // padding: 1.5rem;
  font-family: 'Roboto', sans-serif;
}

.form-label {
  font-weight: 500;
}

.steps {

  .steps-progressbar {
    position: relative;
    height: 4px;
    background-color: #F6F6F6;

    &__active {
      position: absolute;
      height: 100%;
      background-color: var(--bs-primary);
    }
  }

  .steps-nav {
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    gap: 40px;

    &__item {
      display: flex;
      gap: 15px;
      align-items: center;
    }

    &__icon {
      display: grid;
      place-items: center;
      width: 50px;
      height: 50px;
      overflow: hidden;
      border: 1px solid #E9E9E9;
      border-radius: 50px;
      flex-shrink: 0;
      color: #A1A1AA;
      font-size: 18px;
    }

    &__det {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    &__step {
      font-size: 14px;
      color: var(--bs-primary);
    }

    &__title {
      font-size: 16px;
      font-weight: 500;
    }

    &__active {
      .steps-nav {
        &__icon {
          background-color: var(--bs-primary);
          color: var(--bs-white);
          border: none;
        }

      }
    }

    &__done {
      .steps-nav {
        &__icon {
          background-color: #F2F7FE;
          color: var(--bs-primary);
          border: 1px solid #3054CB;
        }

      }
    }
  }

  .steps-content {
    padding: 40px 0;
    max-width: 960px;
    margin: 0 auto;

    &__header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 40px;
    }

    &__header-desc {
      display: flex;
      flex-direction: column;
      gap: 5px;

      h4 {
        margin: 0;
      }

      p {
        margin: 0;
      }
    }

    &__main {
      padding: 40px 0;
    }
  }
}

.verticles {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  &__item {
    position: relative;

    &-button {
      display: flex;
      flex-direction: column;
      gap: 15px;
      border: 1px solid #E9E9E9;
      border-radius: 6px;
      padding: 10px;
      align-items: center;
      justify-content: center;
      min-height: 150px;
      background-color: var(--bs-white);
    }

    &-icon {}

    &-name {
      font-size: 18px;
      font-weight: 500;
    }

    input[type="radio"]:not(checked)+label {
      border: 1px solid #E9E9E9;
      background-color: var(--bs-white);
    }

    input[type="radio"]:checked+label {
      border: 1px solid var(--bs-primary);
      background-color: rgba(22, 118, 238, .05);
    }

  }
}

.full-height {
  height: 100vh;

  .title {
    font-size: 2rem;
    font-weight: 500;
  }

  .txt-content {
    max-width: 400px;
  }

  .download-links {
    a {
      img {
        width: 175px;
        height: auto;
      }
    }
  }
}
.location-img-box
{  
  aspect-ratio: 3 / 2;
  img 
  {
    width: 100%;
    border-radius: 20px;
    height: 100%;
    object-fit: cover;
  }
}
